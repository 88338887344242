<template>
  <div class="billing-docs-table">
    <base-table
      :columns="columns"
      :list="paginList"
      :loading="isLoading"
      class="billing-docs-table__table"
      @sort-change="onSortChange"
    >
      <billing-docs-table-row
        v-for="item in paginList"
        :key="item.id"
        :item="item"
        :columns="columns"
        :item-module="itemModule"
        :is-route="isRoute"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="small"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable.vue';
import BillingDocsTableRow from './BillingDocsTableRow.vue';
import NewPagination from '@/components/Pagination/NewPagination';
import isDate from 'date-fns/isDate';
import showErrorModal from '@/mixins/showErrorModal';
// import BaseLoader from '@/components/BaseLoader/BaseLoader';
/*import Vue from 'vue';*/
// import data from 'inputmask/lib/dependencyLibs/data';
/*import Link from '@/components/Configurator/components/Link';*/
import pagination from '@/mixins/pagination';
export default {
  name: 'BillingDocsTable',
  components: { BaseTable, BillingDocsTableRow, NewPagination },
  mixins: [showErrorModal, pagination],
  props: {
    itemtype: {
      type: String,
      required: true,
      validator: val => ['payment', /*'invoice',*/ 'contract', 'customdocs'].includes(val),
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    dateFilter: {
      type: Object,
      default: null,
      validator: obj => {
        return !obj || (obj.start && isDate(obj.start) && obj.end && isDate(obj.end));
      },
    },
  },
  data() {
    return {
      //stepOptions: [5, 10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 5,
      },
      tableHead: [
        {
          key: 'number',
          label: this.$t('tableHead.number'),
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'date',
          label: this.$t('tableHead.date'),
          sort: {
            prop: 'date',
            order: 'asc',
          },
        },
        {
          key: 'customer',
          label: this.$t('tableHead.customer'),
        },
        {
          key: 'company',
          label: this.$t('tableHead.company'),
        },
        {
          key: 'contractname',
          label: this.$t('tableHead.contractname'),
        },
        {
          key: 'dtype',
          label: this.$t('tableHead.dtype'),
        },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          sort: {
            prop: 'cost',
            order: 'asc',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          sort: {
            prop: 'status_orig',
            order: 'asc',
          },
        },
        {
          key: 'download',
          style: {
            width: '80px',
          },
        },
        {
          key: 'pay',
          style: {
            width: '80px',
          },
        },
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      /*checkedList: [],*/
      colsByType: {
        payment: ['check', 'number', 'date', 'customer', 'phone', 'cost', 'status', 'pay', 'more'],
        invoice: ['number', 'date', 'customer', 'company', 'cost', 'status', 'more'],
        contract: [
          'number',
          'date',
          'customer',
          'company',
          'contractname',
          'dtype',
          'status',
          'more',
        ],
        customdocs: ['number', 'date', 'dtype', 'download'],
      },
      moduleMain: 'moduleBilling.moduleDocs.modulePayment',
      /*removeOrderModal: null,
      sureModal: null,*/
      itemModules: {
        payment: 'modulePayment',
        customdocs: 'moduleCustomdocs',
        contract: 'moduleContract',
      },
    };
  },
  computed: {
    /*checkedListInstances() {
      return this.dataset.reduce((acc, item) => {
        if (this.checkedList.includes(item.id)) acc.push(item);
        return acc;
      }, []);
    },*/
    isRoute() {
      return this.$route.name === 'BillingContracts';
    },
    moduleMainPath() {
      return this.moduleMain.replaceAll('.', '/');
    },
    itemModule() {
      return this.itemModules[this.itemtype];
    },
    sortedList() {
      // для скрытия служебных платежей
      // return this.$store.getters[`moduleBilling/moduleDocs/${this.itemModule}/GET_SORTED_LIST`];
      return this.$store.getters[
        `moduleBilling/moduleDocs/${this.itemModule}/GET_SORTED_LIST`
      ].filter(x => x.subaccountamount_iso !== '0.00 RUB');
    },
    /*sortedListBlock() {
      // для скрытия служебных платежей
      // return this.$store.getters[`moduleBilling/moduleDocs/${this.itemModule}/GET_SORTED_LIST`];
      return this.$store.getters[
        'moduleBilling/moduleDocs/modulePaymentBlock/GET_SORTED_LIST_BLOCK'
      ].filter(x => x.subaccountamount_iso !== '0.00 RUB');
    },*/
    list() {
      // if (this.itemModule === 'moduleContract') return this.sortedList;
      if (!this.dateFilter || this.itemModule === 'moduleContract') return this.sortedList;
      const { start, end } = this.dateFilter;
      return this.sortedList.filter(i => i.date >= start && i.date <= end);
    },
    /*listPaymentBlock() {
      // if (this.itemModule === 'moduleContract') return this.sortedList;
      if (!this.dateFilter || this.itemModule === 'moduleContract') return this.sortedListBlock;
      const { start, end } = this.dateFilter;
      return this.sortedList.filter(i => i.date >= start && i.date <= end);
    },*/
    isLoading() {
      return this.$store.state.moduleBilling.moduleDocs[this.itemModule].isLoading;
    },
    columns() {
      return this.tableHead.filter(col => {
        return this.colsByType[this.itemtype].includes(col.key);
      });
    },
    sort() {
      return this.$store.state.moduleBilling.moduleDocs[this.itemModule].sort;
    },
    // paginList() {
    //   if (this.pagin.step === 'all') return this.list;
    //   const { start, step } = this.pagin;
    //   const end = start + step;
    //   return this.list.slice(start, end);
    // },
    // paginListBlock() {
    //   const { start, step } = this.pagin;
    //   const end = start + step;
    //   return this.listBlock.slice(start, end);
    // },
    showPagination() {
      return this.list.length > this.stepOptions[0];
    },
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isRoute) {
      this.tableHead.splice(0, 2);
    }
    if (this.sort) {
      this.fetchList();
    }
    this.updateSort(this.sort);
    this.fetchList();
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch(`moduleBilling/moduleDocs/${this.itemModule}/reset`).then(() => {
      next();
    });
  },
  methods: {
    /*onChecked(id, val) {
      const index = this.checkedList.findIndex(i => i === id);
      // if val is true and is not in list
      if (val && !~index) this.checkedList.push(id);
      // if val is false and is in list
      else if (!val && ~index) this.checkedList.splice(index, 1);
      // else do nothing
    },*/
    fetchList() {
      this.$store
        .dispatch(`moduleBilling/moduleDocs/${this.itemModule}/fetchList`)
        .catch(e => this.showError(e));
    },

    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit(`moduleBilling/moduleDocs/${this.itemModule}/SET_SORT`, { prop, order });
    },
    updateSort(sort) {
      const col = this.columns.find(i => i.sort && i.sort.prop === sort.prop);
      if (col?.sort.order) col.sort.order = sort.order;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "number": "Номер",
      "date": "Дата",
      "customer": "Плательщик",
      "company": "Компания",
      "contractname": "Вид договора",
      "dtype": "Тип документа",
      "cost": "Сумма",
      "status": "Статус"
    },
    "askSure": {
      "title": "Подтвердите действие",
      "text": "Вы уверены, что хотите {msg}?",
      "confirm": "Да",
      "cancel": "Отмена"
    },
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 счетов | Выбран 1 счет | Выбрано {n} счета | Выбрано {n} счетов",
    "removeOrders": {
      "action": "Удалить счета",
      "text": "удалить счета",
      "success": "Счета успешно удалены",
      "fail": "Что-то пошло не так, попробуйте повторить позднее",
      "ok": "Понятно"
    }
   }
  }

</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-docs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center, wrap);
    margin: -0.25rem -0.25rem 1.25rem;

    &-start,
    &-end {
      margin: 0.25rem;
    }

    &-end {
      flexy(flex-end, center);
    }
    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    //+breakpoint(sm-and-up) {
    //  margin-top: 2.5rem;
    //}
  }
}
</style>
