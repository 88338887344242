<template>
  <base-table-row
    :columns="columns"
    v-bind="item"
    class="billing-docs-table-row"
    col-class="billing-docs-table-row__col"
  >
    <template #number>
      <plain-button
        v-if="itemModule == 'modulePayment'"
        :href="docLink"
        target="_blanc"
        color="primary"
      >
        {{ item.number }}
      </plain-button>
      <div v-else-if="!isRoute">
        {{ item.number }}
      </div>
    </template>
    <template v-if="!isRoute" #date>
      {{ $d(item.date, 'short') }}
    </template>
    <template #phone>
      {{ item.phone || '' }}
    </template>
    <template #customer>
      {{ item.sender_name || item.client_name }}
    </template>
    <template #company>
      {{ item.company_name || '' }}
    </template>
    <template #contract_name>
      {{ item.contract_name || '' }}
    </template>
    <template #dtype>
      {{ item.dtype === 'Счёт' ? 'Счёт-фактура' : item.dtype || '' }}
    </template>
    <template #cost>
      {{ $n(item.cost, 'currency') || '' }}
    </template>
    <template #status>
      <span :class="`${item.statusColor}-color`">
        {{ item.status }}
      </span>
    </template>
    <template v-if="item.link" #download>
      <plain-button
        :href="item.link.href"
        :target="item.link.target"
        :title="item.link.title || item.number"
        color="primary"
      >
        {{ $t('download') }}
      </plain-button>
    </template>
    <template v-if="hasPayTool" #pay>
      <div class="doc-info">
        <plain-button
          v-if="hasPayTool && !isBank && item.status_orig === '1'"
          :disabled="
            !tools.pay.isEnable(current) ||
            item.paymethod_module === 'pmruwire' ||
            isTextVerification
          "
          color="primary"
          class="doc-info__button"
          @click="pay"
        >
          {{ $t('pay') }}
        </plain-button>
        <plain-button
          v-if="isTextVerification"
          v-tooltip="{
            content: text,
            autoHide: false,
            placement: 'top-start',
            container: false,
          }"
          icon="warn"
          size="medium"
          color="del"
          tabindex="-1"
          class="doc-info__button"
        />
      </div>
      <plain-button
        v-if="isBank"
        :href="link"
        :target="'_blank'"
        color="primary"
        class="doc-info__button"
      >
        {{ $t('download') }}
      </plain-button>
    </template>
    <template v-if="contextMenu.length" #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="billing-docs-table-row__popover"
      >
        <plain-button
          icon="more"
          class="billing-docs-table-row__more"
          @click="togglePopoverState"
        />
        <template #popover>
          <base-context-menu
            :tariff="item"
            :menu="contextMenu"
            :module-main="moduleMain"
            :tools="tools"
            class="billing-docs-table-row__context-menu"
          />
        </template>
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import BaseContextMenu from '@/components/BaseContextMenu/BaseContextMenu.vue';
import PlainButton from '@/components/Buttons/PlainButton.vue';
import popoverFix from '@/mixins/popoverFix';
import docsContextMenu from '../../mixins/docsContextMenu';
import contextMenuHandler from '@/mixins/contextMenuHandler';
import wizardPay from '@/mixins/billmgr/wizardPay';
import updateBalance from '@/mixins/billing/updateBalance';
import handleRedirect from '@/mixins/billing/handleRedirect';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';
import BaseConfigurator from '@/models/base/BaseConfigurator';

export default {
  name: 'BillingDocsTableRow',
  components: { BaseTableRow, BaseContextMenu, PlainButton },
  mixins: [
    popoverFix,
    docsContextMenu,
    contextMenuHandler,
    wizardPay,
    handleRedirect,
    updateBalance,
  ],
  props: {
    isRoute: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    itemModule: {
      type: String,
      required: true,
      validator: val =>
        ['modulePayment', /*'invoice',*/ 'moduleContract', 'moduleCustomdocs'].includes(val),
    },
  },
  data() {
    return {
      moduleMain: `moduleBilling.moduleDocs.${this.itemModule}`,
    };
  },
  computed: {
    host() {
      return window.location.href.split('#').slice(0, 1);
    },
    text() {
      return `С 01.12.2023 оплата счетов посредством электронных денег/СБП возможна после проверки телефонного номера либо после оплаты банковским переводом/банковской картой. Пожалуйста, выполните замену или подтверждение номера мобильного телефона в <strong><a href="${this.host}#/iam/personal">Профиле</a></strong> <br /><br /> <strong> <a href="https://support.rusonyx.ru/index.php?/Knowledgebase/Article/View/633/0/kk-vystvit-schet">Как выписать новый счет</a></strong>`;
    },
    isTextVerification() {
      let verification = false;
      let paymentDisabled = false;
      if (
        /*this.item.paymethod_module === 'pmyandexcheckout' ||*/
        this.item.paymethod_module === 'pmassist' &&
        this.item.status_orig === '1'
      ) {
        paymentDisabled = true;
      }
      if (!this.isVerification && paymentDisabled) {
        verification = true;
      }
      return verification;
    },
    isVerification() {
      let isPaymentCurrent = false;
      if (
        this.$store.state.moduleBilling.moduleDocs.modulePaymentBlock.listPaymentBlock.find(i => {
          return (
            (i['paymethod_module'] === 'pmruwire' || i['paymethod_module'] === 'pmsberbank') &&
            i.status_orig === '4'
          );
        }) ||
        this.$store.state.moduleProfile.profile.need_phone_validate === false
      ) {
        isPaymentCurrent = true;
      }
      return isPaymentCurrent;
    },
    link() {
      return `${this.appHost}/billmgr?func=payment.print.pdf&elid=${
        this.current.id
      }&out=pdf_print&auth=${localStorage.getItem('token')}`;
    },
    docLink() {
      return `${this.appHost}/billmgr?func=payment.print&elid=${
        this.current.id
      }&out=doc_print&auth=${localStorage.getItem('token')}`;
    },
    isBank() {
      return this.item.paymethod_module === 'pmruwire';
    },
    tools() {
      return this.$store.state.moduleBilling.moduleDocs[this.itemModule].tools;
    },
    appHost() {
      return this.$store.state.moduleApp.host;
    },
    paymentId() {
      return this.item.id;
    },
    current() {
      return this.item;
    },
    hasPayTool() {
      return this.tools && this.tools.pay;
    },
  },
  methods: {
    docRedirect(item) {
      // console.log('item', item);
      const { tool } = item;
      const out = item.key === 'print' || item.key === 'list' ? 'doc_print' : 'pdf_print';
      const token = localStorage.getItem('token');
      const url = `${this.appHost}/billmgr?func=${tool.func}&elid=${this.current.id}&out=${out}&auth=${token}`;
      window.open(url);
    },
    pay() {
      // this.$gtm.trackEvent({
      //   event: '_event_arrange', // Event type [default = 'interaction'] (Optional)
      //   category: 'billing',
      //   action: 'click',
      //   label: 'pay',
      //   value: 5000,
      //   noninteraction: false, // Optional
      // });
      // console.log(this.item);
      // console.log(this.current);
      // console.log(this.item.paymethod_module);
      // console.log(this.current.sender_id);
      const hasPaymethod = this.current.paymethod_module && this.current.sender_id;
      // console.log(hasPaymethod);
      if (hasPaymethod) this.payByTool();
      else this.payByWizard();
    },
    payByTool() {
      const item = {
        tool: this.tools.pay,
        type: 'edit',
        toolParams: {
          elid: this.current.id,
          elname: this.current.number,
        },
      };
      this.runTool(item);
    },
    payByWizard() {
      this.setPaymentWizardParams({
        billorder: this.current.billorder,
        payment_id: this.paymentId,
      });
      this.runWizardPay()
        .then(async data => {
          const res = await this.handleRedirect(data.ok);
          if (res.ok) {
            const text =
              res.func === 'redirect'
                ? this.$t('success.redirect')
                : this.$t('success.pay', { num: this.$n(this.cost, 'currency') });
            this.showSuccessModal(text);
          }
          this.updateBalance();
        })
        .then(() =>
          this.$store
            .dispatch(`moduleBilling/moduleDocs/${this.itemModule}/fetchList`)
            .catch(e => this.showError(e))
        )
        .catch(() => {})
        .finally(() => this.resetWizardParams());
    },
    setPaymentWizardParams(startParams = {}) {
      this.configuratorClass = PaymentMethodConfigurator;
      this.startFunc = 'payment.add';
      this.startParams = startParams;
      this.showResFunc.success = false;
    },
    resetWizardParams() {
      this.configuratorClass = BaseConfigurator;
      this.startFunc = '';
      this.startParams = {};
      this.showResFunc.success = this.showResModal;
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessPayment',
        size: 'small',
        text,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "success": {
      "pay": "Счет успешно оплачен, {num} списано с лицевого счета",
      "redirect": "Продолжите оплату и дождитесь обновления данных"
    }
  }
}
</i18n>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-docs-table-row {
  &__popover {
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      max-width: 100%;
      width: calc(100vw - 6rem);
    }
  }
}
  .doc-info{
    flexy(center)
    //display: flex;
    &__button{
      padding-left: 0.5rem !important;
      width: 2.25em;
      height: 1.25em;
}
}
</style>
