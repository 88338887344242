<template>
  <div class="billing-docs">
    <div class="billing-docs__head">
      <div class="billing-docs__nav">
        <tabs :list="nav" theme="pills" class="billing-docs__nav-tabs">
          <template v-slot:item="{ item }">
            <router-link exact-active-class="active" :to="item.to">
              {{ item.title }}
            </router-link>
          </template>
        </tabs>
      </div>
      <div class="billing-docs__filter">
        <base-datepicker-filter
          v-if="itemtype !== 'contract'"
          :periods="periods"
          :period="period"
          :value="dateFilter"
          class="billing-docs__filter-item"
          @init="onFilterInit"
          @change="onFilterChange"
        />
      </div>
      <div v-if="isShowButtonEdo" class="billing-docs__actions">
        <base-button @click="openEdoTicket" class="btn btn-primary">{{
          $t('newTicket')
        }}</base-button>
      </div>
    </div>
    <div class="billing-docs__content">
      <base-loader v-if="loading"></base-loader>
      <router-view v-else :itemtype="itemtype" :date-filter="dateFilter" />
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import qs from 'qs';
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter.vue';
import storeMixin from '@/layouts/Support/mixins/index';
import BaseButton from '@/components/Buttons/BaseButton.vue';
export default {
  name: 'BillingDocs',
  components: { tabs, BaseDatepickerFilter, BaseButton },
  mixins: [storeMixin],
  data() {
    return {
      inn: '',
      loading: false,
      nav: [
        {
          title: this.$t('nav.payments'),
          to: { name: 'BillingPayments' },
        },
        /*{
          title: this.$t('nav.invoices'),
          to: { name: 'BillingInvoices' },
        },*/
        {
          title: this.$t('nav.contracts'),
          to: { name: 'BillingContracts' },
        },
        {
          title: this.$t('nav.customdocs'),
          to: { name: 'BillingCustomdocs' },
        },
      ],
      dateFilter: { start: new Date(), end: new Date() },
      periods: ['month', 'quarter', 'halfyear', 'year'],
      period: 'year',
    };
  },
  computed: {
    itemtype() {
      return this.$route.meta.itemtype;
    },
    // payersInfo() {
    //   return this.$store.state.moduleProfile.payers;
    // },
    profileInfo() {
      return {
        id: this.$store.state.moduleProfile?.profile?.id,
        payersId: this.$store.state.moduleProfile?.payers?.id,
      };
    },
    isEdoChecked() {
      const ticket = this.$store.state.moduleSupport.allTickets.filter(
        x => x.name === 'Запрос на подключение ЭДО'
      );
      const arhive = this.$store.state.moduleSupport.allTicketsArhive.filter(
        x => x.name === 'Запрос на подключение ЭДО'
      );
      // console.log(ticket, arhive);
      return ticket?.length || arhive?.length > 0;
    },
    isShowButtonEdo() {
      if (this.$store.state.moduleBilling.modulePayers.list.length) {
        return (
          ['2', '3'].includes(
            this.$store.state.moduleBilling.modulePayers.list[0].profiletype_orig
          ) && !this.isEdoChecked
        );
      } else return false;
    },
  },
  mounted() {
    this.fetchTicketsForEdo();
    this.checkPayFromAttachedCard();
    if (this.profileInfo?.id && this.profileInfo?.payersId)
      this.fetchProfileInfo(this.profileInfo.payersId);
  },
  methods: {
    async fetchTicketsForEdo() {
      await this.$store.dispatch('moduleSupport/fetchAllTicketsArhive');
      await this.$store.dispatch('moduleSupport/fetchAllTickets');
    },
    onFilterInit(value) {
      this.dateFilter = value;
    },
    fetchProfileInfo(id) {
      // console.log(id);
      this.$store.dispatch('moduleStart/fetchProfileInfo', id).then(data => {
        this.inn = data?.vatnum || 'не заполнено';
      });
    },
    openEdoTicket() {
      const params = {
        department: '1094990',
        size: 'medium',
        ticketThemes: 'Запрос на подключение ЭДО',
        profile: {
          inn: this.inn,
          ...this.profileInfo,
        },
      };
      this.addNewTicketEdo(params);
    },
    checkPayFromAttachedCard() {
      const url = window.location.href.split('?')[1];
      const parse = qs.parse(url);
      if (parse?.module === 'yandexcheckout') this.loading = true;
      setTimeout(
        () =>
          this.$store
            .dispatch('moduleBilling/moduleDocs/modulePayment/fetchList')
            .catch(e => this.showError(e))
            .finally(() => (this.loading = false)),
        1000
      );
      // console.log(parse);
      // console.log(window.location.href.split('/#/')[1]);
    },
    onFilterChange(value) {
      // console.log(value);
      this.dateFilter = value;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "export": "Экспортировать в CSV",
    "newTicket": "Подключение ЭДО",
    "send": "Подтвердить",
    "btn": {
      "send": "Подтвердить",
      "url": "К тикету",
      "ok": "Закрыть"
    },
    "message": {
      "sent": "Заявка на подключение ЭДО принята. Обработка заявок осуществляется по рабочим дням в период с 10:00 до 17:00. Ожидайте нашего приглашения. Следить за статусом заявки можете здесь."
    },
    "nav": {
      "payments": "Счета",
      "invoices": "Акты",
      "contracts": "Договоры",
      "customdocs": "Закрывающие документы"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-docs {
  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      flexy(flex-start, center, wrap);
    }
    +breakpoint(md-and-up) {
      //flex-wrap: nowrap;
    }
  }
  &__nav {
    flex: 1 0 100%;
    +breakpoint(lg-and-up) {
      flex: 0 0 auto;
    }
  }
  &__filter {
    margin: 1rem 0;

    +breakpoint(sm-and-up) {
      margin-right: auto;
    }
    +breakpoint(lg-and-up) {
      margin: 0 1rem;
      margin-right: auto;
    }
  }
  &__export {
    +breakpoint(sm-and-up) {
      margin: 1rem 0 1rem auto;
    }
  }
}
</style>
